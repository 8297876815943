class MrHoverAnimation extends HTMLElement {
	private mouseEnterHandler = () => {
		this.setAttribute( 'data-hover', '' );
	};

	private animationStartHandler = () => {
		const currentlyRunning = parseInt( this.getAttribute( 'data-running-animations' ) ?? '0', 10 );
		this.setAttribute( 'data-running-animations', ( currentlyRunning + 1 ).toString() );
	};

	private animationEndHandler = () => {
		requestAnimationFrame( () => {
			const currentlyRunning = parseInt( this.getAttribute( 'data-running-animations' ) ?? '0', 10 );
			if ( currentlyRunning - 1 <= 0 ) {
				this.removeAttribute( 'data-running-animations' );
				this.removeAttribute( 'data-hover' );
			} else {
				this.setAttribute( 'data-running-animations', ( currentlyRunning - 1 ).toString() );
			}
		} );
	};

	connectedCallback() {
		this.addEventListener( 'mouseenter', this.mouseEnterHandler );
		this.addEventListener( 'animationstart', this.animationStartHandler );
		this.addEventListener( 'animationend', this.animationEndHandler );
	}

	disconnectedCallback() {
		this.removeEventListener( 'mouseenter', this.mouseEnterHandler );
		this.removeEventListener( 'animationstart', this.animationStartHandler );
		this.removeEventListener( 'animationend', this.animationEndHandler );
	}
}

window.customElements.define( 'mr-hover-animation', MrHoverAnimation );
